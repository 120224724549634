import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "src/types/routes";
import './Navigation.less'

interface INavigation {
  key: string
  title: string
  path: string
}

export const data: INavigation[] = [
  { key: 'uploadAndShare', title: 'Upload & Share', path: Routes.ENHANCE_AND_SHARE },
  { key: 'searchAndDownload', title: 'Search & Download', path: Routes.SEARCH_AND_DOWNLOAD },
  { key: 'myLeads', title: 'Database', path: Routes.MY_LEADS },
  { key: 'learningCenter', title: 'Learning Center', path: Routes.LEARNING_CENTER },
  { key: 'campaigns', title: 'Campaigns', path: Routes.MY_LEADS_CAMPAIGN },
]

const NavigationMenu: FC = () => {
  const location = useLocation()

  const activeKey = data.find(({ path }) => location.pathname.includes(path))?.key

  return (
    <nav className={'navigation-menu'}>
      <ul className={'navigation-menu__list'}>
        {data.map(({ key, title, path }) => (
          <li 
            key={key} 
            data-active={activeKey === key}
            className={'navigation-menu__item'}
          >
            <Link className={'body-md-light navigation-menu__link'} to={path}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavigationMenu