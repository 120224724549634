import { Flex, Modal, Typography } from "antd"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { subscriptionPlans } from "src/module/subscription"
import WelcomeImage from "../../assets/images/welcome-image.png"
import { SubscriptionCard } from "../../module/subscription/assets/SubscriptionCard"
import CustomButton from "../ui/CustomButton"
import "./WelcomeModal.less"

type Props = {
  isOpen: boolean
  onClose: () => void
  welcomeStep: number
  setWelcomeStep: Dispatch<SetStateAction<number>>
}
const WelcomeModal: FC<Props> = ({ isOpen, onClose, setWelcomeStep, welcomeStep }) => {
  const [selectedPlan, setSelectedPlan] = useState("")
  return (
    <Modal
      className={"welcome-modal"}
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      width={welcomeStep === 1 ? 680 : 896}
      centered={true}
      footer={null}
    >
      {welcomeStep === 1 ? (
        <div className={"first-step-wrapper"}>
          <div className={"image-wrapper"}>
            <img src={WelcomeImage} alt={"Welcome!"} />
          </div>
          <div className={"first-step-text"}>
            <Typography.Text className={"header"}>Welcome to Franchise Lead Genie!</Typography.Text>
            <Typography.Paragraph className={"content"}>
              Congratulations on joining! For the next <span className={"bold"}>3 days</span>, you’ll have access to our{" "}
              <span className={"bold"}> Plus plan </span> absolutely free. Explore all the enhanced search filters and
              features without any cost. After the trial period, you can choose the plan that best fits your needs.
            </Typography.Paragraph>
          </div>
          <Flex gap={16}>
            <CustomButton onClick={onClose} type={"default"} text={"No, Thanks"} block={true} />
            <CustomButton
              type={"primary"}
              onClick={() => setWelcomeStep(2)}
              block={true}
              text={"Get Started for Free"}
            />
          </Flex>
        </div>
      ) : (
        <div className={"second-step-wrapper"}>
          <div className={"second-step-text"}>
            <Typography.Text className={"header"}>Welcome to Franchise Lead Genie!</Typography.Text>
            <Typography.Paragraph className={"content"}>
              Compare features and choose the subscription that helps you search smarter and achieve more.
            </Typography.Paragraph>
          </div>
          <div className={"subscription-cards"}>
            <SubscriptionCard
              setSelectedPlan={setSelectedPlan}
              plan={subscriptionPlans.standard}
              isSelected={subscriptionPlans.standard.title === selectedPlan}
              isForModal={true}
            />
            <SubscriptionCard
              setSelectedPlan={setSelectedPlan}
              plan={subscriptionPlans.plus}
              isSelected={subscriptionPlans.plus.title === selectedPlan}
              isForModal={true}
            />
            <SubscriptionCard
              setSelectedPlan={setSelectedPlan}
              plan={subscriptionPlans.pro}
              isSelected={subscriptionPlans.pro.title === selectedPlan}
              isForModal={true}
            />
          </div>
          <Flex gap={16}>
            <CustomButton onClick={onClose} type={"default"} text={"Continue with Free Plan"} block={true} />
            <CustomButton
              type={"primary"}
              onClick={() => setWelcomeStep(2)}
              block={true}
              text={"Change Plan"}
              disabled={!selectedPlan}
            />
          </Flex>
        </div>
      )}
    </Modal>
  )
}

export { WelcomeModal }
