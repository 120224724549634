import { FC, SVGProps } from "react"
import { ReactComponent as ArrowDown } from "src/assets/icons/arrowDown.svg"
import { ReactComponent as ArrowLeft } from "src/assets/icons/arrowLeft.svg"
import { ReactComponent as ArrowRight } from "src/assets/icons/arrowRight.svg"
import { ReactComponent as Bell } from "src/assets/icons/bell.svg"
import { ReactComponent as BlueFile } from "src/assets/icons/blue-file.svg"
import { ReactComponent as BlueStars } from "src/assets/icons/blue-stars.svg"
import { ReactComponent as CheckmarkCircle } from "src/assets/icons/checkmarkCircle.svg"
import { ReactComponent as ChevronDown } from "src/assets/icons/chevronDown.svg"
import { ReactComponent as DownloadFile } from "src/assets/icons/downloadFile.svg"
import { ReactComponent as Error } from "src/assets/icons/error.svg"
import { ReactComponent as Filters } from "src/assets/icons/filters.svg"
import { ReactComponent as Google } from "src/assets/icons/google.svg"
import { ReactComponent as Headphones } from "src/assets/icons/headphones.svg"
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg"
import { ReactComponent as Share } from "src/assets/icons/share.svg"
import { ReactComponent as Spin } from "src/assets/icons/spin.svg"
import { ReactComponent as Sparkles } from "src/assets/icons/sparkles.svg"
import { ReactComponent as Trashcan } from "src/assets/icons/trashcan.svg"
import { ReactComponent as Warning } from "src/assets/icons/warning.svg"
import { ReactComponent as XMark } from "src/assets/icons/x-mark.svg"

export const icons = {
  blueStars: BlueStars,
  google: Google,
  bell: Bell,
  xMark: XMark,
  blueFile: BlueFile,
  headphones: Headphones,
  trashCan: Trashcan,
  checkmarkCircle: CheckmarkCircle,
  spin: Spin,
  warning: Warning,
  error: Error,
  arrowLeft: ArrowLeft,
  arrowDown: ArrowDown,
  filter: Filters,
  downloadFile: DownloadFile,
  arrowRight: ArrowRight,
  search: SearchIcon,
  chevronDown: ChevronDown,
  sparkles: Sparkles,
  share: Share,
} as const

export type IconsType = keyof typeof icons
type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconsType }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type as IconsType] ?? null
  return Icon && <Icon {...svgProps} />
}
