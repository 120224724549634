import { FC } from "react"
import Loader from "src/components/layouts/Loader"
import { useAuth } from "src/contexts/AuthProvider"
import { CardSubscriptionInfo } from "./assets/CardSubscriptionInfo"
import { SubscriptionCard } from "./assets/SubscriptionCard"
import { CardCredits } from "./assets/CardCredits"
import "./index.less"

export const subscriptionPlans = {
  free: {
    title: "Free Plan",
    text: "For getting started with essentials.",
    price: "Free",
    features: ["Search by category", "Use earned credits"],
  },
  standard: {
    title: "Standard Plan",
    text: "Easily narrow results by cities or states.",
    price: "$300",
    features: ["300 credits/month", "Search by category", "Search by city or state"],
  },
  plus: {
    title: "Plus Plan",
    text: "Refine searches with demographics.",
    price: "$400",
    features: ["400 credits/month", "Search by category", "Search by city or state", "Add demographic filters"],
  },
  pro: {
    title: "Pro Plan",
    text: "Gain deeper financial insights.",
    price: "$500",
    features: [
      "500 credits/month",
      "Search by category",
      "Search by city or state",
      "Add demographic filters",
      "Search by net worth",
    ],
  },
}

export const Subscription: FC = () => {
  const { user, isLoading } = useAuth()

  console.log(user)

  return (
    <div className="subscription">
    <div className={"topper-cards"}>
      <CardSubscriptionInfo />
      <CardCredits credits={isLoading ? <Loader spinning size={"large"} /> : user?.credits} />
    </div>
    <div className={"subscription-cards"}>
      <SubscriptionCard plan={subscriptionPlans.free} isActive={user?.subscriptionType === 'free'} />
      <SubscriptionCard plan={subscriptionPlans.standard} isActive={user?.subscriptionType === 'standard'} />
      <SubscriptionCard plan={subscriptionPlans.plus} isActive={user?.subscriptionType === 'plus'} />
      <SubscriptionCard plan={subscriptionPlans.pro} isActive={user?.subscriptionType === 'pro'} />
    </div>
    </div>
  )
}
