import { Typography } from "antd"
import { FC, ReactNode } from "react"
import "./CardCredits.less"

interface Props {
  credits: number | ReactNode
}

export const CardCredits: FC<Props> = ({ credits }) => {
  return (
    <div className={"credits-card"}>
      <Typography.Text className={"title"}>{credits}</Typography.Text>

      <Typography.Text className={"text"}>Total credits</Typography.Text>
    </div>
  )
}
