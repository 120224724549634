import { Button, ButtonProps } from "antd"
import { FC } from "react"
import "./index.less"

interface Props extends ButtonProps {
  text: string
}

const CustomButton: FC<Props> = ({ text, ...props }) => {
  return (
    <Button {...props} size={props.size || "large"} className={`custom-button ${props.className || ""}`}>
      {text}
    </Button>
  )
}

export default CustomButton
