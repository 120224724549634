import { Button, ButtonProps, Image, Typography } from "antd"
import { FC, Fragment, useEffect, useState } from "react"
import { useLocation,useNavigate } from "react-router-dom"
import { useAuth } from "src/contexts/AuthProvider"
import { Routes } from "src/types/routes"
import './UserProfileButton.less'
import { WelcomeModal } from "../modals"

export const UserProfileButton: FC<ButtonProps> = ({ ...props }) => {
  const { user } = useAuth()
  const location = useLocation()
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false)
  const [welcomeStep, setWelcomeStep] = useState(1)

  const isProfilePath = location.pathname.includes(Routes.PROFILE)

  const navigate = useNavigate()
  const onClose = () => {
    setIsWelcomeModalOpen(false)
    localStorage.setItem("welcome-seen", "true")
  }
  useEffect(() => {
    setIsWelcomeModalOpen(!user?.subscriptionType && localStorage.getItem("welcome-seen") !== "true")
  }, [user])
  return (
    <Button 
      onClick={() => navigate(Routes.PROFILE)} 
      aria-label={'user profile'}
      className={`user-profile-button ${props.className || ''} ${isProfilePath ? 'active' : ''}`}
      {...props}
    >
      <Image 
        src={user?.avatar || ''} 
        preview={false} 
        width={40} 
        height={40}  
        className={'user-profile-image'} 
      />

      <div className={'user-profile-info'}>

        {user?.firstName && user?.lastName
          ? <Fragment>
              <Typography.Text className={'name'}>
                {user?.firstName}&nbsp;{user?.lastName}
              </Typography.Text>

              <Typography.Text className={'link'}>
                {user?.credits} Credits
              </Typography.Text>
            </Fragment>
          : <Fragment>
              <Typography.Text className={'email'}>
                {user?.email}
              </Typography.Text>

              <Typography.Text className={'link'}>
                {user?.credits} Credits
              </Typography.Text>
            </Fragment>
        }
      </div>
      <WelcomeModal isOpen={isWelcomeModalOpen} onClose={onClose} welcomeStep={welcomeStep} setWelcomeStep={setWelcomeStep} />
    </Button>
  )
}