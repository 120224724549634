import { Typography } from "antd"
import "./CardSubscriptionInfo.less"

export const CardSubscriptionInfo = () => {
  return (
    <div className={"subscription-info-card"}>
      <div className='content'>
        <Typography.Text className={"title"}>Subscription Plans</Typography.Text>
        <Typography.Text className={"text"}>
          Compare features and choose the subscription that helps you search smarter and achieve more.
        </Typography.Text>
      </div>
    </div>
  )
}
